<template>
  <div class="video-wrapper">
    <video autoplay playsinline muted loop id="onboardVideo">
      <source src="../assets/movies/Film.mp4" type="video/mp4">
    </video>
     <div class="onboard-text-wrapper">
      <swiper class="onboard-swiper" ref="mySwiper">
          <swiper-slide>
          <div class="content-slide-wrapper">
            <div class="onboard-icon-wrapper"><img src="../assets/app_icon/Logo_BM_pion_400.png" class="onboard-icon" alt="logo"/></div>
            <div class="slide-text">
              <div class="onboard-headline mb-1">Odkrywaj</div>
              <div class="onboard-subheadline">Możesz poznawać ludzi przez książki i książki dzięki ludziom.</div>
              <div class="slide-arrow" @click="slideToNext(1)">›</div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide>
           <div class="content-slide-wrapper">
            <div class="onboard-icon-wrapper"><img src="../assets/app_icon/Logo_BM_pion_400.png" class="onboard-icon" alt="logo"/></div>
            <div class="slide-text">
              <div class="onboard-headline mb-1">Czytaj</div>
              <div class="onboard-subheadline">Ustaw swój plan czytelniczy i znajdź czas na czytanie.</div>
              <div class="slide-arrow" @click="slideToNext(2)">›</div>
            </div>
           </div>
          </swiper-slide>
          <swiper-slide>
            <div class="content-slide-wrapper">
              <div class="onboard-icon-wrapper"><img src="../assets/app_icon/Logo_BM_pion_400.png" class="onboard-icon" alt="logo"/></div>
              <div class="slide-text">
                <div class="onboard-headline mb-1">Organizuj</div>
                <div class="onboard-subheadline">Dodawaj, porządkuj i recenzuj książki.</div>
                <div class="slide-arrow" @click="slideToNext(3)">›</div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="view-container">
            <Login />
          </swiper-slide>
        </swiper>
     </div>
  </div>
</template>
<script>
// import FAQ from '../components/Faq'
import Login from './Login'
export default {
  name: 'OnboardingSlider',
  components: {
    Login
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  },
  mounted () {
    this.swiper.slideTo(0, 500, true)
  },
  methods: {
    slideToNext (index) {
      this.swiper.slideTo(index, 500, true)
    }
  }
}
</script>
<style lang="scss" scoped>
  #onboardVideo {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    z-index: 0;
  }
  .onboard {
    &-text-wrapper {
      position: fixed;
      top: 0%;
      left: 0%;
      color: #f1f1f1;
      width: 100%;
      padding: 0px;
      z-index: 10;
    }
    &-swiper {
    width: 100%;
    height: 100vh;
  }
  &-icon {
    width: 150px;
    height: 150px;

    &-wrapper {
      display: flex;
      justify-content: center;
    }
  }
  &-headline {
    font-size: 25px;
    font-weight: 200;
    line-height: 35px;
  }

  &-subheadline {
    text-align: center;
    padding: 0 65px;
    font-weight: bolder;
    font-size: 16px;
  }
}
  .content-slide-wrapper {
    height: 65vh;
    width: 100%;
    position: fixed;
    top: 35%;
  }
  .slide-text {
    box-sizing: border-box;
    text-align: center;
    width: 100%;
    height: 50%;
    margin-top: 15%;
    position: relative;;
  }
  .slide-arrow {
    font-size: 40px;
    color: #fff;
    text-align: center;
    // margin-top: 25%;
    position: absolute;
    bottom: 15%;
    left: 48%;

  }
  .loginview-container {
    z-index: 100;
    width: 100%;
    height: 100%;
  }
  .view-container {
    width: 100%;
    background-color: red;
  }
</style>
